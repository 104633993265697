<template>
    <section class="text-gray-600 body-font">
      <div class="container px-2 py-5 mx-auto">
        <div class="border border-gray-200 p-4 rounded-lg bg-green-50">
          <div class="text-center mb-2">
            <span class="text-2xl font-bold">Konstitusiya</span>
          </div>
          <!-- <pre class="mb-4">{{ JSON.stringify(constitutions, 2) }}</pre> -->

          <div class="grid-cols-1 grid gap-4">
            <div v-for="constitution in constitutions" :key="constitution.id" class="border border-gray-200 p-2 rounded-md bg-white shadow-md transition-shadow text-lg overflow-hidden relative hover:shadow-xl">
              <div class="absolute bg-green-500" style="height: 120%; width: 10px; margin-top: -10px;margin-left: -10px;"></div>
              <div class="ml-4">
                  
                  <p class="text-xl font-bold border-b-4 inline border-black">{{ constitution.url }}</p>
                  <p class="my-4" v-html="constitution.name"></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  
  export default {
    computed: {
      ...mapState('ConstitutionStore', ['constitutions']),
    },
    methods: {
      ...mapActions('ConstitutionStore', ['getConstitutions']),
      colorChange(color) {
        return `border-${color}-500 bg-${color}-100 text-${color}-800`;
      }
    },
    created() {
      this.getConstitutions();
    },
  }
  </script>